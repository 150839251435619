import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEO from "../../components/seo"
import PersonCard from "../../components/person-card"
import Breadcrumbs from "../../components/breadcrumbs"
import { HomeLink, MeetDoctorLink, MeetTeamLink } from "../../components/links"
import Alert from "../../components/alert"

const AboutTeamPage = ({ data }) => {
  const images = {
    althea: getImage(data.althea),
    doctor: getImage(data.doctor),
    jennifer: getImage(data.jennifer),
    joanne: getImage(data.joanne),
    linh: getImage(data.linh),
    nancy: getImage(data.nancy),
    sharon: getImage(data.sharon),
    shiara: getImage(data.shiara),
    vivian: getImage(data.vivian),
  }
  return (
    <>
      <SEO title="Meet our team" />

      <Breadcrumbs>
        <HomeLink />
        <MeetTeamLink />
      </Breadcrumbs>

      <article className="prose dark:prose-invert max-w-none prose-headings:text-brand-primary prose-headings:dark:text-brand-secondary">
        <h2>Meet our team</h2>
        <Alert>
          Meet our diverse team members.
          <br />
          We speak English, French, Vietnamese, Mandarin, Cantonese, Taiwanese,
          Tagalog and Ilocano.
        </Alert>
      </article>
      <div className="grid grid-cols-1 md:grid-cols-3">
        <PersonCard
          name="Dr. Huy D. Nguyen"
          image={
            <GatsbyImage
              image={images.doctor}
              alt="Portrait of Dr. Huy D. Nguyen"
              className="mask mask-circle"
            />
          }
          jobTitle="Certified Orthodontist"
          education="DMD, Cert. Ortho., Tufts University"
          languages="English, French, Vietnamese, Cantonese"
        >
          <MeetDoctorLink
            title="Full Bio"
            className="self-center w-fit mt-2 btn btn-primary text-zinc-300"
          />
        </PersonCard>
        <PersonCard
          name="Nancy"
          image={
            <GatsbyImage
              image={images.nancy}
              alt="Portrait of Nancy"
              className="mask mask-circle"
            />
          }
          jobTitle="Orthodontic Hygienist (RDH)"
          languages="English, Mandarin, Cantonese, Taiwanese"
        ></PersonCard>
        <PersonCard
          name="Vivian"
          image={
            <GatsbyImage
              image={images.vivian}
              alt="Portrait of Vivian"
              className="mask mask-circle"
            />
          }
          jobTitle="Orthodontic Hygienist (RDH)"
          languages="English, Mandarin"
        ></PersonCard>
        <PersonCard
          name="Sharon"
          image={
            <GatsbyImage
              image={images.sharon}
              alt="Portrait of Sharon"
              className="mask mask-circle"
            />
          }
          jobTitle="Certified Dental Assistant"
          languages="English, Mandarin"
        ></PersonCard>
        <PersonCard
          name="Jennifer"
          image={
            <GatsbyImage
              image={images.jennifer}
              alt="Portrait of Jennifer"
              className="mask mask-circle"
            />
          }
          jobTitle="Certified Dental Assistant"
          languages="English, Tagalog"
        ></PersonCard>
        <PersonCard
          name="Shiara"
          image={
            <GatsbyImage
              image={images.shiara}
              alt="Portrait of Shiara"
              className="mask mask-circle"
            />
          }
          jobTitle="Dental Assistant"
          languages="English, Tagalog, Ilocano"
        ></PersonCard>
        <PersonCard
          name="Althea"
          image={
            <GatsbyImage
              image={images.althea}
              alt="Portrait of Althea"
              className="mask mask-circle"
            />
          }
          jobTitle="Office Manager"
          languages="English, Cantonese, Mandarin"
        ></PersonCard>
        <PersonCard
          name="Linh"
          image={
            <GatsbyImage
              image={images.linh}
              alt="Portrait of Linh"
              className="mask mask-circle"
            />
          }
          jobTitle="Treatment Coordinator"
          languages="English, Vietnamese"
        ></PersonCard>
        <PersonCard
          name="Joanne"
          image={
            <GatsbyImage
              image={images.joanne}
              alt="Portrait of Joanne"
              className="mask mask-circle"
            />
          }
          jobTitle="Schedule Coordinator"
          languages="English, Vietnamese"
        ></PersonCard>
      </div>
    </>
  )
}
export default AboutTeamPage

export const query = graphql`
  query {
    doctor: file(relativePath: { eq: "team/dr-huy-nguyen.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    althea: file(relativePath: { eq: "team/althea.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    jennifer: file(relativePath: { eq: "team/jennifer.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    joanne: file(relativePath: { eq: "team/joanne.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    linh: file(relativePath: { eq: "team/linh.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    nancy: file(relativePath: { eq: "team/nancy.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    sharon: file(relativePath: { eq: "team/sharon.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    shiara: file(relativePath: { eq: "team/shiara.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    vivian: file(relativePath: { eq: "team/vivian.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
