import React from "react"
import PropTypes from "prop-types"
import {
  AcademicCapIcon,
  BriefcaseIcon,
  TranslateIcon,
} from "@heroicons/react/outline"

const PersonCard = ({
  children,
  education,
  image,
  jobTitle,
  languages,
  name,
}) => (
  <>
    <div className="card prose-headings:text-brand-primary prose-headings:dark:text-brand-secondary">
      <figure className="px-8 pt-8">{image}</figure>
      <div className="card-body">
        <h2 className="card-title">{name}</h2>
        {jobTitle && (
          <p className="text-sm text-gray-500 dark:text-gray-300">
            <BriefcaseIcon className="w-5 h-5 inline mr-1" />
            {jobTitle}
          </p>
        )}

        {education && (
          <p className="text-sm text-gray-500 dark:text-gray-300">
            <AcademicCapIcon className="w-5 h-5 inline mr-1" />
            {education}
          </p>
        )}

        {languages && (
          <p className="text-sm text-gray-500 dark:text-gray-300">
            <TranslateIcon className="w-5 h-5 inline mr-1" />
            {languages}
          </p>
        )}
        {children}
      </div>
    </div>
  </>
)

PersonCard.propTypes = {
  children: PropTypes.node,
  education: PropTypes.string,
  image: PropTypes.node.isRequired,
  jobTitle: PropTypes.string.isRequired,
  languages: PropTypes.string,
  name: PropTypes.string.isRequired,
}

export default PersonCard
